@import url(fonts/Brown/fonts.css);

html,
body {
    height: 100%;
    margin: 0;
    font-family: "Brown", monospace;
    background-color: #ffffff;
}

.badge {
    height: 30px;
    width: 30px;
}

.bg-success {
    background-color: #55e77e !important;
}

.nav {
    --bs-nav-link-color: #55e77e;
    --bs-nav-link-hover-color: #157347;
}

.btn-success {
    --bs-btn-bg: #55e77e;
    --bs-btn-border-color: #55e77e;
    --bs-btn-disabled-bg: #adb5bd;
    --bs-btn-disabled-border-color: #adb5bd;
}

.btn-collection {
    border-width: 0px;
    border-radius: 5px;
    color: #fff;
    padding: 4px;
    margin-right: 0.8em;
    font-size: 12px;
}

.btn-collection:hover {
    background-color: #157347;
    color: #fff;
}

.green {
    background-color: #55e77e;
}

.grey {
    background-color: #adb5bd;
}

.btn-danger {
    --bs-btn-bg: #f9665c;
    --bs-btn-border-color: #f9665c;
}

.header-text {
    font-size: 2em;
}
.text-green {
    color: #55e77e;
}
.text-grey {
    color: #aaaaaa;
}
